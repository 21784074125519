const a=[
    {
        component: "slider1", data: [
            {page_format:"carousel",to:"/", id: 1, title: "Home Page", description: "Saitpoint Limited", image: "/carousel/landingpage.jpg" },
            {page_format:"carousel",to:"/page/learning", id: 2, title: "Learning", description: "Henacing skills", image: "/carousel/learning.jpg" },
            {page_format:"carousel",to:"/page/cloud", id: 3, title: "The Cloud Journey", description: "the cloud transformation", image: "/carousel/cjourney.jpg" },
            {page_format:"carousel",to:"/page/medical", id: 4, title: "Medical Science", description: "Healthcare consulting professional ", image: "/carousel/medical.jpg" },
            {page_format:"carousel",to:"/page/government", id: 5, title: "UK Government", description: "UK Government Projects ", image: "/carousel/government.jpg" },
        ]
    },
   { component: "slider2", data: [
        { title: "International Trade", heading: "International Trade", image: "/businesssectionconfig/trade.jpg", p: "Hadoop to Azure Databricks Migration", date: "17-12-2021" },
        { title: "NHS Englan", heading: "Focus Event", image: "/businesssectionconfig/NHS_England_logo.svg.png", p: "Organisation Event", date: "24-21-11" },
        { title: "British Redcross", heading: "Annual Report for the Saitpoint", image: "/businesssectionconfig/br.jpg", p: "Budget Planning", date: "24-21-11" },
        { title: "Clydesdale Bank", heading: "Annual Report for the Saitpoint", image: "/businesssectionconfig/cb.jpg", p: "Budget Planning", date: "24-21-11" },
        { title: "British Telecom", heading: "Annual Report for the Saitpoint", image: "/businesssectionconfig/bt.jpg", p: "Budget Planning", date: "24-21-11" },
        { title: "CLCH", heading: "Learn with our webinar series", image: "/businesssectionconfig/CLCH.jpg", p: "Hadoop to Azure Databricks Migration", date: "17-12-2021" },
        { title: "Landmarc", heading: "Learn with our webinar series", image: "/businesssectionconfig/land.jpg", p: "Hadoop to Azure Databricks Migration", date: "17-12-2021" },
        { title: "EASO", heading: "Learn with our webinar series", image: "/businesssectionconfig/EASOlogo.png", p: "Hadoop to Azure Databricks Migration", date: "17-12-2021" },
        { title: "Fyresmiles Recruitment", heading: "Focus Event", image: "/businesssectionconfig/fm.jpg", p: "Organisation Event", date: "24-21-11" },
        { title: "MoJ", heading: "Focus Event", image: "/businesssectionconfig/MoJ.JPG", p: "Organisation Event", date: "24-21-11" },
        { title: "BAE Systems", heading: "Focus Event", image: "/businesssectionconfig/bae.png", p: "Organisation Event", date: "24-21-11" },
        { title: "Whithall Resources", heading: "Annual Report for the Saitpoint", image: "/businesssectionconfig/Whitehall.JPG", p: "Budget Planning", date: "24-21-11" },
        { title: "The Commmonwealth", heading: "Annual Report for the Saitpoint", image: "/businesssectionconfig/thecommon.jpg", p: "Budget Planning", date: "24-21-11" },
        { title: "TCO", heading: "Annual Report for the Saitpoint", image: "/businesssectionconfig/tcologo.png", p: "Budget Planning", date: "24-21-11" },
        { title: "Sapphire Systems", heading: "Learn with our webinar series", image: "/businesssectionconfig/Sapphire-logo.png", p: "Hadoop to Azure Databricks Migration", date: "17-12-2021" },
        { title: "MoD", heading: "Learn with our webinar series", image: "/businesssectionconfig/MoD.JPG", p: "Hadoop to Azure Databricks Migration", date: "17-12-2021" },
        { title: "Gilead", heading: "Learn with our webinar series", image: "/businesssectionconfig/gilead.jpg", p: "Hadoop to Azure Databricks Migration", date: "17-12-2021" },
        { title: "Uniper Energy", heading: "Learn with our webinar series", image: "/businesssectionconfig/uniperenergy.jpg", p: "Hadoop to Azure Databricks Migration", date: "17-12-2021" },
        { title: "Syngenta", heading: "Learn with our webinar series", image: "/businesssectionconfig/syngenta.jpg", p: "Hadoop to Azure Databricks Migration", date: "17-12-2021" },
        { title: "SCWCSUNHS", heading: "Learn with our webinar series", image: "/businesssectionconfig/scwcsunhs.jpg", p: "Hadoop to Azure Databricks Migration", date: "17-12-2021" },
        { title: "Essex Police HQ", heading: "Learn with our webinar series", image: "/businesssectionconfig/Essex.jpg", p: "Hadoop to Azure Databricks Migration", date: "17-12-2021" },
        { title: "Centrica", heading: "Learn with our webinar series", image: "/businesssectionconfig/centrica.jpg", p: "Hadoop to Azure Databricks Migration", date: "17-12-2021" },
        { title: "Microosft", heading: "Focus Event", image: "/businesssectionconfig/ms.jpg", p: "Organisation Event", date: "24-21-11" },
        { title: "Morgan Syndal Grup", heading: "Focus Event", image: "/businesssectionconfig/morgonsg.jpg", p: "Organisation Event", date: "24-21-11" },
        { title: "UHG", heading: "Focus Event", image: "/businesssectionconfig/uhg.jpg", p: "Organisation Event", date: "24-21-11" },
        


       
    ]},
    {
        component: "services", data: {
            title: "13+ years of successful stories on achieving our mission to make your business better",
            data: [{ id: 1, image: "/homepageabout/healthcare.jpg", title: "Healthcare", href: "/category/healthcare" },
            { id: 2, image: "/homepageabout/govern.jpg", title: "Public Sector", href: "/page/government" },
            { id: 3, image: "/homepageabout/medical-service-logos.jpg", title: "Medical Science", href: "/page/medical" },
            { id: 4, image: "/homepageabout/e&u.jpg", title: "Energy Utilities", href: "/" },
            { id: 5, image: "/homepageabout/retail.jpg", title: "Retail", href: "/" },
            { id: 6, image: "/homepageabout/all.jpg", title: "All Industries", href: "/" },]
        }
    },
    {
        component: "business", data: {
            title: "Business Transformation with Microsoft Cloud Solutions",
            data: [
                { id: 1, to: "/category/1", image: "/businesssectionconfig/azure.jpg", title: "Azure", description: "From insights to innovation, transform your business with secure Microsoft Azure" },
                { id: 2, to: "/category/1", image: "/businesssectionconfig/365.jpg", title: "Microsoft 365", description: "Empower your teams be more collaborative, creative and secure with Microsoft 365" },
                { id: 3, to: "/category/1", image: "/businesssectionconfig/dynamics.jpg", title: "Dynamics 365", description: "Combine the power of data and intelligence with Microsoft Dynamics 365" },
            ]
        }
    },
    {
        component: "cards", data: {
            caption: "Insights and Highlights",
            pagination:true,
            data:[
                { id: 1, title: "Regulatory Affairs", tags: "Medical Science", image: "/sectionconfig/regaf.png", desc: 'This Presentation gives an up-to-date overview of the Regulatory Affairs organization and is used for new hire orientation to show how International RA interacts with other RA functions such as Regulatory Compliance.  This presentation is made to all new hires during their first month in Regulatory.', href: "/page/medical", hasicon: true, icon: "" },
                { id: 2, title: "Client stories", tags: "Client stories", image: "/sectionconfig/client stories.jpg", desc: "Client Stories at an IT consulting company showcases the transformative solutions delivered to businesses across industries. These narratives highlight Saitpoint Limited's ability to address challenges, optimize systems, and drive client innovation. Saitpoint Limited demonstrates its expertise, technical skills, and customer-centric approach by sharing detailed success stories. These stories inspire confidence, showcasing measurable outcomes like cost savings, improved efficiency, and enhanced digital transformation.", href: "https://saitpoint.com", hasicon: false, icon: "" },
                { id: 3, title: "Meet our people", tags: "Meet our people", image: "/sectionconfig/meetourpeople.jpg", desc: 'Meet Our People offers a glimpse into the talented professionals driving our IT consulting success. With diverse expertise, innovative thinking, and a passion for solving complex challenges, our team is dedicated to delivering exceptional results. Please get to know the individuals who bring ideas to life and make a difference for our clients daily.', href: "https://saitpoint.com", hasicon: false, icon: "" },
                { id: 4, title: "Case studies & stories", tags: "Case studies & stories", image: "/sectionconfig/case.jpg", desc: 'Case Studies and Stories highlight our proven track record of delivering impactful IT solutions to clients across industries. Each case study delves into real-world challenges, innovative approaches, and measurable results, showcasing our expertise and commitment to excellence. These stories reflect the value we bring by empowering businesses to achieve their goals through tailored technology strategies', href: "https://saitpoint.com", hasicon: false, icon: "" },
                { id: 5, title: "Cloud Journey", tags: "Cloud Journey", image: "/sectionconfig/cloudjrn.jpg", desc: '"Cloud Journey" captures our expertise in guiding businesses through seamless cloud transformations. From strategy to implementation, we help organizations harness the power of cloud technology to enhance agility, scalability, and innovation. Our tailored approach ensures a smooth transition, empowering clients to unlock the full potential of the cloud.', href: "https://saitpoint.com", hasicon: false, icon: "" },
                { id: 6, title: "Voices of Change", tags: "Voices of Change", image: "/sectionconfig/voices.jpg", desc: '"Voices of Change" showcases the transformative impact of technology through the perspectives of those leading and experiencing innovation. These stories highlight how our IT consulting solutions empower individuals and organizations to embrace change, drive progress, and create lasting value in an ever-evolving digital landscape.', href: "https://saitpoint.com", hasicon: false, icon: "" },
                { id: 7, title: "Capabilities", tags: "IT Capabilities", image: "/sectionconfig/itcap.jpg", desc: 'Capabilities highlights our broad range of IT consulting services tailored to meet diverse business needs. From strategy and innovation to implementation and support, we deliver solutions that drive efficiency, scalability, and success. Our expertise empowers organizations to navigate challenges and achieve their digital transformation goals.', href: "https://saitpoint.com", hasicon: false, icon: "" },
                { id: 8, title: "Our Operations", tags: "IT Operations", image: "/sectionconfig/itops.jpg", desc: 'Our Operations reflects our commitment to delivering innovative IT solutions through seamless project execution and cutting-edge technology. We specialize in custom application development, IT product creation, and strategic consulting to empower businesses with efficient, scalable, and transformative solutions', href: "https://saitpoint.com", hasicon: false, icon: "" },
                { id: 9, title: "Applications", tags: "IT Applications", image: "/sectionconfig/itapps.jpg", desc: "Our Applications showcases our expertise in delivering custom IT products, tools, and comprehensive implementation services. We design innovative software solutions that address business challenges, streamline operations, and enhance performance, ensuring seamless integration and effective deployment to meet your organization's unique needs.", href: "https://saitpoint.com", hasicon: false, icon: "" },
            ]
        }
    },

    {
        component: "blogs", data: [
            {
                id: 1,
                t1: "Tailored Message for Saitpoint Limited",
                t2: "Insights from our experts",
                t3: "Microsoft 365 Solution Architect & Operations Delivery Management",
                t4: "Responsible for managing a company's overall operations. This may include delegating and directing agendas, driving profitability, managing company organizational structure, strategy, and communicating with the board. After spending over 10 years with Saitpoint Limited, I feel very privileged to be writing this message as the Director of Saitpoint Limited. This is a special time in our company's history as we celebrate our 10th anniversary. We began our journey on 21st June 2011 and have spent the past decade building our brand, which has become synonymous with our commitment to helping our clients share knowledge and create innovation. We have seen much change over the past 10 years as a company. It is also important to note what has not changed at Digital Business: our commitments to providing exceptional client service and living our core values: Excellence in Everything, Leadership by Example, Integrity and Transparency, Focus on the Client and Employee Centered. Looking ahead, we are focused on accelerating the execution of our growth strategy while continuing to build on the strength of our brand—in helping our clients share knowledge and create innovation and in bringing positive change to the communities in which we work and live. I am incredibly excited about this journey and honestly believe the best of Digital Business is yet to come.",
                image1: "/businesssectionconfig/ceo.jpg",
                image2: "/businesssectionconfig/h.jpg",
                to: "/",
                name: "Hari Bondalapati",
                desg: "Operational Manager",
            },
            {
                id: 2,
                t1: "DevOps",
                t2: "Designing DevOps Strategy",
                t3: "Design and implement strategies for collaboration code management, security, compliance, delivery, and monitoring",
                t4: "Saitpoint Limited has successfully executed several projects utilizing advanced DevOps tools, leading to enhanced development efficiency and operational excellence. CI/CD Pipelines Automated deployment pipelines using Jenkins and GitLab CI/CD, reducing deployment time and ensuring rapid delivery of updates. Infrastructure as Code (IaC) Leveraged Terraform and Ansible to automate infrastructure provisioning, enabling consistent and repeatable environments. Monitoring and Logging Integrated Prometheus, Grafana, and ELK Stack for real-time performance monitoring and centralized logging, improving system reliability. Collaboration and Issue Tracking Improved team collaboration with Azure DevOps and Jira, streamlining project management and incident resolution workflows. Implementing DevOps Development Processes, Continuous Integration, Continuous Delivery, Dependency Management, Application Infrastructure and Continuous Feedback.",
                image1: "/businesssectionconfig/devops.jpg",
                image2: "/businesssectionconfig/team2.jpg",
                to: "/",
                name: "DevOps Team",
                desg: "Digital Engagement Team",
            },

        ]
    },
    {
        component: "events", data: [
            // item starts
            {
                component: "slider3", data: [
                    { title: "Celebrations", heading: "Saitpoint Ltd Celebrations", image: "/businesssectionconfig/Event3.jpg", p: "Saitpoint Ltd's 2025 Celebrations honor the company's recent achievements, showcasing milestones in innovation, growth, and excellence while bringing together employees, partners, and stakeholders to celebrate collective success", date: "21-02-2025" },
                    { title: "Leadership Engagement", heading: "Purpose of Leadership", image: "/businesssectionconfig/Event4.jpg", p: "Characteristics of a Strong Leadership Engagement IT Team", date: "31-01-2025" },
                    { title: "Business Meeting", heading: "Saitpoint Limited", image: "/businesssectionconfig/Event5.jpg", p: "An IT Business Meeting is a collaborative session where stakeholders discuss technology strategies and align IT initiatives with business goals.", date: "21-03-2025" },
                    { title: "Microsoft Engagement", heading: "Saitpoint Limited", image: "/businesssectionconfig/Event7.jpg", p: "Involves collaboration to provide innovative solutions, enhance technological capabilities, and drive digital transformation initiatives tailored to the company's needs", date: "05-05-2025" },
                   
                ]
            },
            // item ends
            // item starts
            {
                component: "slider3", data: [
                    { title: "Saitpoint Ltd Webinar Series", heading: "Saitpoint Limited IT Cloud Webinar Series", image: "/businesssectionconfig/Hadoop.png", p: "Saitpoint Limited Cloud is a dynamic platform showcasing the latest advancements in cloud computing, offering expert insights, practical solutions, and strategies to optimize IT operations and drive innovation.", date: "10-04-2025" },
                    { title: "Saitpoint Limited Event", heading: "Organizational Event", image: "/businesssectionconfig/orgevent.jpg", p: "Organizational collaborative gathering aimed at fostering innovation, aligning IT strategies with business objectives, and strengthening team dynamics through knowledge sharing and strategic discussions.", date: "11-03-2025" },
                    { title: "Tech Conferences and Summits", heading: "Public events and Conferences", image: "/businesssectionconfig/techconf.png", p: "Large-scale events that bring together industry experts, developers, and business leaders to discuss emerging technologies, share innovations, and explore trends in the IT sector", date: "25-03-2025" },
                    { title: "Saitpoint Budget", heading: "Annual Report for the Saitpoint", image: "/businesssectionconfig/event8.jpg", p: "company's performance, achievements, and strategic initiatives, while the Budget outlines financial allocations to drive growth, innovation, and operational excellence", date: "05-04-2025" },
            
                   
                ]
            },
            // item ends

        ]
    },
    
    {
        component: "speak", data: {
            title: "Saitpoint Speak",
            backgroundImage: "/businesssectionconfig/speak.jpg",
            video: "logo.png",
            url: "'https://www.w3schools.com/html/mov_bbb.mp4'",
            text: "Saitpoint Limited is a dynamic IT solutions provider dedicated to delivering innovative and customized technology services to clients across diverse industries. With a strong focus on regulatory compliance, clinical operations, and enterprise process automation, the company leverages advanced technologies like AI, cloud computing, and data analytics to solve complex business challenges. Saitpoint partners with leading organizations, including pharmaceutical and life sciences companies, to streamline operations, enhance efficiency, and drive digital transformation. Committed to excellence and client success, Saitpoint Limited is a trusted partner for businesses worldwide."
            //https://www.youtube.com/watch?v=fVp88d3BH0k
        }
    },
    {
        component: "partners", data: {
            data: [
                { id: 1, image: "/img/micro.png", },
                { id: 2, image: "/businesssectionconfig/greatplace.jpg", },
            ]
        }
    },
    {
        component: "ready", data: {
            title: "Ready to begin your digital evolution?",
            label: "Contact Us",
            to: "/page/contact",
        }
    },
]
export default a 