import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Carousel } from 'react-carousel-minimal';

const PA = props => `<div class="slider1-main">
  <div class="flex">
  <h1 class="s1-t1">${props.title}</h1>
  <a class="s1-t2" href='${props.to}'>Learn More</a>
  </div>
</div> 
<div class="SpAddress"><p class="spAdressTitle">Contact Us</p><br/>
<div>
 Add:&nbsp;&nbsp;&nbsp; 4f, Holywell Way,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Staines-Upon-Thames,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; England, TW19 7SH<br/>
 Phone:&nbsp;&nbsp; +44 1784 695214<br/>
 Email:&nbsp; <a href="mailto:info@saitpoint.com">info@saitpoint.com</a>  </div>
`

export default function Slider1({ data }) {
  let mainData = data.map(x => ({ ...x, caption: PA(x) }))
  const captionStyle = {
    fontSize: '17px',
    //fontWeight: 'bold',
    textShadow: "0 10px 10px transparent",
    color: "white"
  }
  const slideNumberStyle = {
    fontSize: '0px',
    fontWeight: 'bold',
  }
  var settings = {
    dots: true,
    infinite: true,
    speed: 10,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    controls: true,
    nextArrow: <i style={{ color: "red" }} className="fa fa-chevron-right"></i>,
    prevArrow: <i style={{ color: "red" }} className="fa fa-chevron-left"></i>,
  };
  if (false) {

    return (
      <Slider {...settings} >
        {data.map((slide, index) => <div key={index} className="slider1-container">
          <img alt="best in country saitpoint.net"  height={100} src={slide.image} />
          <p className="legend">{slide.title}</p>
          <Link to={slide.to}>Learn More</Link>
        </div>
        )}
      </Slider>
    );
  }
  else {
    return <div className="slider1-container-main"><Carousel
      data={mainData}
      time={5000}
      width="100%"
      height="auto"
      captionStyle={captionStyle}
      radius="0"
      slideNumber={true}
      slideNumberStyle={slideNumberStyle}
      captionPosition="top"
      automatic={true}
      dots={true}
      pauseIconColor="red"
      pauseIconSize="40px"
      slideBackgroundColor="black"
      slideImageFit="cover"
      thumbnails={false}
      thumbnailWidth="0px"
      style={{
        textAlign: "center",
        maxWidth: "100%",
        maxHeight: "450px",
        margin: "auto",
      }}
    />
    </div>
  }
}

